import "./App.css";
import cohete from './assets/cohete.png'
import logo from './assets/logo.png'
import BotonWhatsapp from "./components/botonWhatsapp";

function App() {
  return (
      <div className="contenedor">
            <div className="uno"></div>
            <div className="dos"></div>
            <div className="tres"></div>
            <div className="cuatro"></div>
            <div className="cinco"></div>
            <div className="seis"></div>
            <img src={cohete} className="cohete" alt="cohete"/>
            <img src={logo} className="logo" alt="logo"/>
            <div className="texto">
                Estamos creando toda una
nueva experiencia para ti
            </div>
            <a className="mail" href = "mailto:comercial@argled.com.uy">comercial@argled.com.uy</a>
      <BotonWhatsapp />
    </div>
  );
}

export default App;
